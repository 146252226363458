import { $post } from '@/api/http.js'
import common from "@/api/common.js"
import tool from '@/util/tool.js'

//saas端服务分支管理
class Service{

    /**
     * 获取行业方案信息
     * @param {function} fn
     */
    getServerScheme(page,limit,fn){
        let param = {page,limit}
        common.getDataList("getServerFather",param,1).then(res=>fn(res))
    }

    /**
     * 新增或编辑行业方案信息
     * @param {object} param 要添加的数据
     * @param {function} fn
     * @returns
     */
    addOrEditServerScheme(param,fn){
        if( !tool.returnMessage(param.father_name,"请填写方案名称")) return
        if( !tool.returnMessage(param.description,"请填写方案描述")) return
        if( !param.id ) delete param.id
        $post("createOrUpdateServerFather",param,true,1).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 删除行业方案信息
     * @param {number} id
     * @param {function} fn
     */
    deleteSchemen(id,fn){
        tool.confirm("确认删除行业方案信息吗？").then(()=>{
            $post("deleteServerFather",{pk:id},true,1).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取插件信息
     * @param {function} fn
     */
    getSaasPlugin(fn){
        $post('getAuthSassPlugins', {},true, 1).then(res => {
            fn(res.data.plugins)
        })
    }
    /**
     * 获取分支信息
     * @param {number} father_id 行业方案id
     * @param {number} type 1分支 2套餐
     * @param {function} fn
     */
    getBranch(father_id,type,fn){
        $post("getServerListByType",{father_id,type},true,1).then(res=>fn(res.data)).catch(()=>{})
    }

    /**
     * 新增或编辑分支信息
     * @param {object} param
     * @param {function} fn
     * @returns
     */
    addOrEditBranch(param,fn){
        if( !tool.returnMessage(param.father_id,"请选择行业方案")) return
        if( !tool.returnMessage(param.server_name,"请输入分支名称")) return
        if( !tool.returnMessage(param.expire,"请输入到期时间")) return
        if( !param.server_id ) delete param.server_id
        param.rule = JSON.stringify(param.rule)
        param.default = JSON.stringify(param.default)
        $post("createOrUpdateServer",param,true,1).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     *获取分支/套餐详情
     * @param {number} id
     * @param {number} type 1分支 2套餐
     * @param {function} fn
     */
    getBranchDetail(id,type,fn){
        $post("getServerDetailByType",{pk:id,type},true,1).then(res=>fn(res.data)).catch(()=>{})
    }

    /**
     * 新增或编辑套餐信息
     * @param {object}} param
     * @param {function} fn
     */
    addOrEditMeal(param,fn){
        if( !tool.returnMessage(param.server_id,"请输入分支")) return
        if( !tool.returnMessage(param.meal_name,"请输入套餐名称")) return
        if( !param.id ) delete param.id
        $post('createOrUpdateMeal',param,true,1 ).then(res=>{
            tool.message('保存成功');
            fn(res.data)
        },()=>{})
    }

    /**
     * 获取套餐信息
     * @param {number} page
     * @param {number} limit
     * @param {function} fn
     */
    getMeal(page,limit,fn){
        common.getDataList("",{page,limit}).then(res=>fn(res))
    }

    /**
     * 套餐操作
     * @param {number} id
     * @param {number} operation 1删除 2上下架
     * @param {function} fn
     */
    handleMeal(id,operation,fn){
        let param = { pk:id,operation,type:2}

        //删除套餐信息
        if( operation == 1 ){
            tool.confirm("确认要删除该套餐信息吗？").then(()=>{
                $post('doServer',param,true,1).then(res=>{
                    tool.message('操作成功');
                    fn(res.data)
                },()=>{})
            }).catch(()=>{})
        }

        //上下架操作
        if( operation == 2 ){
            $post('doServer',param,true,1).then(res=>{
                tool.message('操作成功');
                fn(res.data)
            },()=>{})
        }
    }

    /**
     * 插件同步
     * @param server_id
     */
    syncPlugin(server_id){
        $post("syncServerMealPlugin",{server_id},true,1).then(res=>{
            if(res.data.status ) {
                tool.message("同步成功")
            }
        })
    }
}
const serviceModel = new Service()
export default serviceModel
