import {reactive, toRaw} from "vue";
import serviceModel from "@/api/saas/service";
import saasSetModel from "@/api/saas/set";
//方案列表信息
export function useScheme(){
    let sState = reactive({
        list:[],
    })
    const getScheme =()=>serviceModel.getServerScheme(1,999,res=>sState.list = res.list)
    return { sState,getScheme }
}

//方案新增、修改、删除操作
export function useEditScheme(fn){
    let esState = reactive({
        show:false,
        form:null
    })

    function showEditScheme(data){
        esState.form = {
            id:data ? data.id :0,
            remark: data ? data.remark : "",
            father_name: data ? data.father_name : "",
            description: data ? data.description : "",
        }
        esState.show = true
    }

    function saveScheme(){
        serviceModel.addOrEditServerScheme( toRaw(esState.form),()=>{
            esState.show = false
            if( typeof fn == 'function' ) fn()
        })
    }
    const deleteScheme = id=>serviceModel.deleteSchemen(id,()=>fn() )

    return{ esState,showEditScheme ,saveScheme,deleteScheme }
}

//分支列表数据
export function   useBranch(){
    let bState = reactive({
        list:[],
        show:false,
    })

    function showBranch(id){
        bState.list = []
        bState.show = true
        serviceModel.getBranch(id,1,res=>bState.list = res)
    }

    return { bState,showBranch }
}

//分支新增、编辑
export function useEditBranch(fn){
    let ebState = reactive({
        show:false,
        form:null,
        plugins:[],
        defaultData: {},
    })

    async function getBranchDetail(id){
        return new Promise(resolve =>serviceModel.getBranchDetail(id,1,res=>resolve(res)))
    }

    async function showEditBranch(row){
        let _d = null
        if( row ){
            await getBranchDetail(row.server_id).then(res=>_d = res)
        }
        ebState.form = {
            father_id:_d ? _d.father_id:0,
            server_id:_d ? _d.server_id:0,
            sort: _d ? _d.sort:99,
            price: _d ? _d.price : "",
            expire: _d ? _d.expire :"",
            is_put_away: _d ? _d.is_put_away :0,
            server_name: _d ? _d.server_name :"",
            description: _d ? _d.description :"",
            discount_price: _d ? _d.discount_price :"",
            renewal_price: _d ? _d.renewal_price :"",
            renewal_discount_price: _d ? _d.renewal_discount_price :"",
            rule:_d &&_d.rule ? {
                goods:_d ? _d.rule.goods :{max_number: -1},
                land:_d ? _d.rule.land :{max_number: -1},
                adoption:_d ? _d.rule.adoption :{max_number: -1},
                storage:_d ? _d.rule.storage :{size: 1024000000},
                plugins:_d ? _d.rule.plugins :[],
            }:{
                goods:{max_number: -1},
                land:{max_number: -1},
                adoption:{max_number: -1},
                storage:{size: 1024000000},
                plugins:[],
            },
            default:_d && _d.default ? {
                index:_d.default.index,
                goods:_d.default.goods,
                adopt:_d.default.adopt,
                land:_d.default.land
            }:{
                index:[],
                goods:[],
                adopt:[],
                land:[]
            }
        }
        ebState.show = true

        //默认数据
        getDefaultData(row.server_id || 0)

        //权限信息
        if( ebState.plugins.length == 0 ){
            serviceModel.getSaasPlugin(res=>{
                let temp = Object.keys(res);
                temp.forEach(item => ebState.plugins.push({ key: item, value: res[item] }) );
            })
        }
    }


    const fetchIndexData = (value)=> getDefaultData(ebState.form.server_id,'index',value)
    const fetchGoodsData = (value)=> getDefaultData(ebState.form.server_id,'goods',value)
    const fetchAdoptData = (value)=> getDefaultData(ebState.form.server_id,'adopt',value)
    const fetchLandData = (value)=> getDefaultData(ebState.form.server_id,'land',value)

    /**
     * 获取可选的默认数据
     * @param server_id 分支的id
     */
    function getDefaultData(server_id=0,scene='all',key=""){
        let param = { scene }
        if( scene !=='all' ) {
            param.type = scene
            if( key === "" ) return
        }
        if( server_id ) param.server_id = server_id
        if( key ) param.key = key
        saasSetModel.getDefaultData(1,999, param,res=>{
            if( scene === 'all' ){
                ebState.defaultData = res
            }else{
                ebState.defaultData[scene] = res[scene]
            }
        })
    }

    function saveBranch(){
        let param = JSON.parse(JSON.stringify(ebState.form))
        serviceModel.addOrEditBranch(param ,()=>{
            ebState.show = false
            if( typeof fn == 'function') fn(ebState.form.father_id)
        })
    }

    const syncPlugin = ()=>serviceModel.syncPlugin(ebState.form.server_id)

    return {
        ebState,
        showEditBranch,
        saveBranch,
        syncPlugin,
        fetchIndexData,
        fetchGoodsData,
        fetchAdoptData,
        fetchLandData
    }
}
